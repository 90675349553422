<template>
	<div>
		<dd-card class="box-card" :disabled.sync="steps.two.disabled" :title="'2. Asociere campuri feed'"
			:collapsed.sync="steps.two.collapsed" @toggledCard="toggledCard" v-loading="loading[2]">
			<el-button :loading="isLoading" type="primary" class="fullWidth" @click="saveAssocFields">Salveaza
			</el-button>
			<div class="separator"></div>
			<div class="title">Tip asociere</div>
			<el-radio-group v-model="currentAssoc" size="small">
				<el-radio :label="0" border>Container produse</el-radio>
				<el-radio :label="1" border>Asociere campuri</el-radio>
			</el-radio-group>
			<el-button class="reset-button" @click="resetFieldAssoc">
				<svg-icon icon-class="refresh" />&nbsp; Reseteaza asocierea</el-button>
			<div class="csvContainer" v-if="currentAssoc === 0 && !isXMLAssoc">
				<div class="separator"></div>
				<div class="sub-title">
					<strong>Completati mai jos numarul randului ce reprezinta capul de tabel (daca este primul rand
						puteti lasa
						necompletat)</strong>
				</div>
				<el-input @input="setCSVProductContainer"></el-input>
			</div>
			<div v-if="currentAssoc === 1">
				<div class="separator"></div>
				<el-table :data="allFields" style="width: 100%" size="mini">
					<el-table-column label="Coloana">
						<template slot-scope="scope">
							{{scope.row.label}}
						</template>
					</el-table-column>
					<el-table-column prop="name" label="Camp feed" align="right">
						<template slot-scope="scope">
							{{scope.row.assoc.replace(/_____/g, " -> ")}}
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div class="separator"></div>
			<div class="title">Arbore feed</div>
			<Box :type="'error'" :active="true" v-if="validationErrors.length > 0">
				<div slot="box-body">
					<h3>Erori asociere:</h3>
					<ul class="rawUl">
						<li v-for="(error, index) in validationErrors" :key="index" v-html="error"></li>
					</ul>
				</div>
			</Box>
			<div class="body" v-if="hasParsedFields">
				<el-row :gutter="10">
					<Tree :data="parsedFields" :horizontal="true" :onlyRow="currentProduct" :firstLevelRoot="typeof parsedFields[0] != 'undefined'" @on-assoc-remove-click="removeAssocField"
						:collapsable="false" :render-content="renderContent" @on-node-click="onNodeClick" />
				</el-row>
			</div>
			<Box :type="'error'" :active="true" v-if="validationErrors.length > 0">
				<div slot="box-body">
					<h3>Erori asociere:</h3>
					<ul class="rawUl">
						<li v-for="(error, index) in validationErrors" :key="index" v-html="error"></li>
					</ul>
				</div>
			</Box>
			<el-button :loading="isLoading" type="primary" class="fullWidth" @click="saveAssocFields">Salveaza
			</el-button>
		</dd-card>

		<el-dialog :visible.sync="fiedlAssocDialog.visible" width="300px" class="feed-assoc-dialog">
			<div slot="title">
				Asociere camp <strong>{{fiedlAssocDialog.title}}</strong>
			</div>
			<div class="cel-fields">
				<div class="field" v-for="(item, index) in availableFields" :key="index">
					<p>
						<el-button-group class="fullWidth">
							<el-button @click="assocField(item.value)" class="assoc-field">{{item.label}}</el-button>
							<el-button v-if="item.required" type="danger" :disabled="true"
								class="assoc-type">Obligatoriu</el-button>
							<el-button v-else type="info" :disabled="true" class="assoc-type">Optional</el-button>
						</el-button-group>
					</p>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	const Box = () =>
		import('vue-info-box-element');
	const DDCard = () => import('@/components/DDCard');
	const Tree = () => import('@/components/Tree');

	import cloneDeep from 'lodash.clonedeep';

	import {
		EventBus
	} from '@/utils/Bus.js';
	import {

	} from '@/api/publicare.js';
	import {
		mapGetters,
		mapState
	} from 'vuex';
	export default {
		name: 'feed-step-2',
		components: {
			'dd-card': DDCard,
			Box,
			Tree,
		},
		data() {
			return {
				fiedlAssocDialog: {
					title: '',
					visible: false
				},
				validationErrors: [],
				currentProduct: 1
			};
		},
		methods: {
			resetFieldAssoc() {
				this.$confirm(
						'Sigur doriti sa resetez asocierea campurilor? Va trebui sa reasociati campurile pentru a putea rula feedul',
						this.$t('general.warning'), {
							confirmButtonText: this.$t('general.yes'),
							cancelButtonText: this.$t('general.no'),
							type: 'warning'
						})
					.then(() => {
						this.$store.dispatch('feeds/ResetFieldAssoc', {
							id: this.feedId
						}).then((res) => {
							if (typeof res.message !== 'undefined') {

								console.log("RR1 ");

								this.resetAssocFields();
								this.$store.commit('feeds/SET_PRODUCT_CONTAINER', '');
								this.$store.commit('feeds/DISABLE_STEP', 'three');
								this.$store.commit('feeds/TOGGLE_COLLAPSE', {
									status: false,
									step: 'three'
								});
								this.parseFeedFile();
							}
						});
					})
					.catch((e) => {
						return false;
					});
			},
			async saveAssocFields() {
				if (this.isXMLAssoc) {
					if (this.assocProductContainer === '') {
						this.sbMsg({
							type: 'warn',
							message: 'Nu ati selectat containerul pentru produs'
						});
						return false;
					}
				} else {
					this.$store.commit('feeds/SET_PRODUCT_CONTAINER', 0);
				}

				this.validationErrors = [];
				const errors = this.validateFields();
				if (errors.length > 0) {
					this.validationErrors = errors;
					this.sbMsg({
						type: 'warn',
						message: 'Nu ati asociat campurile obligatorii. Verifica mai jos campurile necompletate.'
					});
					return false;
				}

				const res = await this.$store.dispatch('feeds/SaveAssocFields', {
					products_container: this.assocProductContainer,
					assoc: JSON.stringify(this.allFields),
					id: this.feedId,
					import_type: this.feedDetails.import_type
				});

				if (typeof res.message === 'object' && Object.keys(res.message).length > 0) {
					this.sbMsg({
						type: 'success',
						message: 'Asocierea a fost salvata. Se incarca urmatorul pas...'
					});
					this.$store.commit('feeds/SET_STEP_FINISHED', 'two');
					EventBus.$emit('assocDone');
					return true;
				}
				return false;
			},
			validateFields() {
				const emptyFields = this.allFields.filter((item) => item.required && item
					.assoc ===
					'');
				// If is update stoc & price we can let only stoc or only price
				if (parseInt(this.feedDetails.import_type) === 0 && emptyFields.length > 0) {
					const stocIndex = emptyFields.findIndex((item) => item.value === 'stoc');
					const priceIndex = emptyFields.findIndex((item) => item.value === 'pretf');
					const modelIndex = emptyFields.findIndex((item) => item.value === 'products_model');
					if (modelIndex > -1) {
						return [`Urmatorul camp este obligatoriu: <strong>${emptyFields[modelIndex].label}</strong>`];
					}
					if (priceIndex > -1 && stocIndex > -1) {
						return [`Urmatorul camp este obligatoriu: <strong>stoc sau pret</strong>`];
					}
					return [];
				} else {
					return emptyFields.map((item) => `Urmatorul camp este obligatoriu: <strong>${item.label}</strong>`);
				}
			},
			forgeObject(data, parent = '', label = '', first = false) {
				const tree = [];
				const isArray = Array.isArray(data);

				for (let n in data) {
					let node = {};
					node.id = first ? n : `${parent}_____${n}`;
					node.label = n;
					node.isArray = isArray && (Array.isArray(data[n]) || typeof data[n] === 'object');
					node.value = (isArray || typeof data[n] === 'object') && typeof data[n] !== 'string' || typeof data[n] == 'undefined' ? '' : (data[n].length > 35 ? data[n].slice(0, 35) + '...' : data[n]);
					node.isProductContainer = false;
					node.assoc = '';

					if (typeof data[n] === 'object' && data[n] != null && Object.keys(data[n]).length > 0) {
						node.children = this.forgeObject(data[n], node.id, n, false);
						node.childrenArray = Array.isArray(node.children) || !Object.keys(node.children).some(isNaN);
					}
					tree.push(node);
				}

				return tree;
			},
			renderContent(h, data) {
				return data.label;
			},
			onNodeClick(e, data) {
				if (this.currentAssoc === 0) {
					if (!this.isXMLAssoc) {
						this.sbMsg({
							type: 'warn',
							message: 'Pentru a asocia containerul produselor pentru feeduri CSV/XLS trebuie sa completati mai sus capul de tabel.',
						});
						return false;
					}
					if (data.id === this.assocProductContainer) { // removing the select
						this.$store.commit('feeds/SET_PRODUCT_CONTAINER', '');
						this.removeProductConainer(data.id, this.parsedFields);
					} else { // adding the select
						this.$store.commit('feeds/SET_PRODUCT_CONTAINER', data.id);
						this.setProductContainer(data.id, this.parsedFields);
					}
				} else {

					if(typeof this.feedDetails['feed_type'] == 'number' && this.feedDetails.feed_type == 2) {
						if (this.assocProductContainer == '') {
							this.sbMsg({
								type: 'warn',
								message: this.$t('feeds.errors.root_key_empty')
							});
							return false;
						} else if (data.id.indexOf(this.assocProductContainer) == -1) {
							this.sbMsg({
								type: 'error',
								message: this.$t('feeds.errors.data_not_in_container')
							});
							return false;
						} else if (data.id == this.assocProductContainer) {
							this.sbMsg({
								type: 'error',
								message: this.$t('feeds.errors.key_equal_root')
							});
							return false;
						}
					}

					this.assocFieldsWith(data);
				}
			},
			removeAssocField(e, data) {
				if (Array.isArray(data)) {
					for (const l in data) {
						if (typeof data[l].assoc.value === 'undefined') return false;
						const index = this.allFields.findIndex((item) => item.value === data[l].assoc.value);
						this.allFields[index].assoc = '';
						this.removeAssoc(data[l].id, this.parsedFields);
					}
				} else {
					if (typeof data.assoc.value === 'undefined') return false;
					const index = this.allFields.findIndex((item) => item.value === data.assoc.value);
					this.allFields[index].assoc = '';
					this.removeAssoc(data.id, this.parsedFields);
				}
			},
			setProductContainer(id, data) {
				if (Array.isArray(data)) {
					for (const l in data) {
						data[l]['isProductContainer'] = data[l]['id'] === id;
						if (typeof data[l].children !== 'undefined' && data[l].children.length > 0) {
							for (const n in data[l].children) this.setProductContainer(id, data[l].children[n]);
						}
					}
				} else {
					data['isProductContainer'] = data['id'] === id;
					if (typeof data.children !== 'undefined' && data.children.length > 0) {
						for (const n in data.children) this.setProductContainer(id, data.children[n]);
					}
				}
			},
			removeProductConainer(id, data) {
				if (Array.isArray(data)) data = data[0];
				if (data.id === id) {
					data.isProductContainer = false;
					return true;
				}
				if (typeof data.children !== 'undefined' && data.children.length > 0) {
					for (const n in data.children) this.removeProductConainer(id, data.children[n]);
				}
			},
			assocFieldsWith(data) {
				this.fiedlAssocDialog.title = data.label;
				this.fiedlAssocDialog.visible = true;
				this.$store.commit('feeds/SET_FIELD_SELECTED', data.id);
			},
			resetAssocDialog() {
				this.fiedlAssocDialog.visible = false;
				this.$store.commit('feeds/SET_FIELD_SELECTED', '');
			},
			assocField(value) {
				const alreadySetIndex = this.allFields.findIndex((item) => item.assoc === this.fieldAssocSelected);
				if (alreadySetIndex > -1) {
					this.allFields[alreadySetIndex].assoc = '';
				}
				const index = this.allFields.findIndex((item) => item.value === value);

				this.allFields[index].assoc = this.fieldAssocSelected;
				this.setAssoc(this.fieldAssocSelected, this.allFields[index], this.parsedFields);
				this.resetAssocDialog();
			},
			setAssoc(selectedId, assocObj, data) {
				if (Array.isArray(data)) {
					for (const l in data) {
						if (this.assocMatch(data[l].id, selectedId, data[l])) {
							data[l].assoc = assocObj;
						} else if (
							typeof data[l].assoc.value !== 'undefined' &&
							data[l].assoc.value === assocObj.value
						) {
							data[l].assoc = '';
						}

						if (typeof data[l].children !== 'undefined' && data[l].children.length > 0) {
							for (const n in data[l].children) this.setAssoc(selectedId, assocObj, data[l].children[n]);
						}
					}
				} else {
					if (this.assocMatch(data.id, selectedId, data)) {
						data.assoc = assocObj;
					} else if (
						typeof data.assoc.value !== 'undefined' &&
						data.assoc.value === assocObj.value
					) {
						data.assoc = '';
					}

					if (typeof data.children !== 'undefined' && data.children.length > 0) {
						for (const n in data.children) this.setAssoc(selectedId, assocObj, data.children[n]);
					}
				}
			},
			assocMatch(id, selectedId, data) {

				if(id === selectedId) {
					return true
				}

				let regexPosition	=	selectedId.indexOf("__*");
				if(regexPosition != -1) {

					let base=	selectedId.substr(0, regexPosition);
					if(id.indexOf(base) != -1) {
						if(typeof data['children'] != 'undefined') {
							return false;
						} else {
							return true;
						}
					}
				}

				return false;
			},
			removeAssoc(id, data) {
				if (Array.isArray(data)) {
					for (const l in data) {
						if (data[l].id === id) {
							data[l].assoc = '';
							return true;
						}
						if (typeof data[l].children !== 'undefined' && data[l].children.length > 0) {
							for (const n in data[l].children) this.removeAssoc(id, data[l].children[n]);
						}
					}
				} else {
					if (data.id === id) {
						data.assoc = '';
						return true;
					}
					if (typeof data.children !== 'undefined' && data.children.length > 0) {
						for (const n in data.children) this.removeAssoc(id, data.children[n]);
					}
				}
			},
			toggledCard(status) {
				this.$store.commit('feeds/TOGGLE_COLLAPSE', {
					status,
					step: 'two'
				});
			},
			resetAssocFields() {
				for (const f in this.allFields) {
					this.allFields[f].assoc = '';
				}
				this.$store.commit('feeds/SET_FEED_ASSOC', {});
			},
			parseFeedFile() {
				if (typeof this.feedDetails.feed_url !== 'undefined' && typeof this.feedDetails.feed_type !==
					'undefined' && this.feedDetails
					.feed_url !== '' && this.feedDetails.feed_type !== '') {
					this.$store.commit('feeds/SET_LOADING', {
						step: 2,
						status: true
					});
					this.$store.dispatch('feeds/ParseFeedFile').then((res) => {
						if (typeof res.message !== 'object' || res.message == null) {
							this.$store.commit('feeds/DISABLE_STEP', 'two');
							this.$store.commit('feeds/ENABLE_STEP', 'one');
							return false;
						}

						if (typeof this.assocFields.length) {
							this.setSavedColumnAssoc();
						}
						this.$store.commit('feeds/SET_LOADING', {
							step: 2,
							status: false
						});
					}).catch((e) => {
						this.$store.commit('feeds/SET_LOADING', {
							step: 2,
							status: false
						});
						this.$store.commit('feeds/DISABLE_STEP', 'two');
						this.$store.commit('feeds/ENABLE_STEP', 'one');
					});
				} else {
					this.sbMsg({
						type: 'warn',
						message: 'Eroare la parsarea feedului. Verificati linkul si salvati din nou primul pas.'
					});
					this.$store.commit('feeds/DISABLE_STEP', 'two');
					this.$store.commit('feeds/ENABLE_STEP', 'one');
					return false;
				}
			},

			drawFeedMapping() {

				if(
						typeof this.assocFields != 'undefined' &&
						Object.keys(this.assocFields).length > 0
				) {
					let assocProposal	=	cloneDeep(this.assocFields);

					if(typeof assocProposal['root'] != 'undefined' || typeof this.feedDetails['feed_type'] == 'number' && this.feedDetails.feed_type != 2 && Object.keys(assocProposal).length > 0) {

						let rootBase	=	'';

						if(typeof assocProposal['root'] == 'string' && assocProposal['root'].length > 0) {
							this.$store.commit('feeds/SET_PRODUCT_CONTAINER', assocProposal['root']);
							this.setProductContainer(assocProposal['root'], this.parsedFields);
							rootBase	=	assocProposal['root'] + "_____";
						}

						let binded	=	{};

						for (const n in this.allFields) {
							for(let keyToBind in assocProposal) {
								let valueToCheck		=	assocProposal[keyToBind];
								if(
										valueToCheck == this.allFields[n].value
								) {

									let assocKey	=	rootBase + this.currentProduct + "_____" + keyToBind;
									let thCheckValue=	this.allFields[n].value;
									if(typeof binded[thCheckValue] == 'undefined' && typeof binded[assocKey] == 'undefined') {
										this.allFields[n].assoc = assocKey;

										this.setAssoc(this.allFields[n].assoc, this.allFields[n], this.parsedFields);
										delete assocProposal[keyToBind];
										binded[assocKey]		=	1;
										binded[thCheckValue]	=	1;
									}
								}
							}
						}

						this.currentAssoc	=	1;
					}

				} else if(this.autoMapFields.length) {

					if(this.autoMapFields && typeof this.autoMapFields[0] != 'undefined') {
						let assocProposal	=	this.autoMapFields[0];

						if(typeof assocProposal['root'] != 'undefined' || typeof this.feedDetails['feed_type'] == 'number' && this.feedDetails.feed_type != 2) {

							let rootBase	=	'';

							if(typeof assocProposal['root'] == 'string' && assocProposal['root'].length > 0) {
								this.$store.commit('feeds/SET_PRODUCT_CONTAINER', assocProposal['root']);
								this.setProductContainer(assocProposal['root'], this.parsedFields);
								rootBase	=	assocProposal['root'] + "_____";
							}

							let binded	=	{};

							for (const n in this.allFields) {
								for(let keyToBind in assocProposal) {
									let valuesToCheck		=	assocProposal[keyToBind];
									for(let valueToCheckIndex in valuesToCheck) {
										let valueToCheck	=	valuesToCheck[valueToCheckIndex];
										if(
												valueToCheck == this.allFields[n].value
										) {

											let assocKey	=	rootBase + this.currentProduct + "_____" + keyToBind;
											let thCheckValue=	this.allFields[n].value;
											if(typeof binded[thCheckValue] == 'undefined' && typeof binded[assocKey] == 'undefined') {
												this.allFields[n].assoc = assocKey;
												this.setAssoc(this.allFields[n].assoc, this.allFields[n], this.parsedFields);
												delete valuesToCheck[valueToCheckIndex];
												binded[assocKey]		=	1;
												binded[thCheckValue]	=	1;
											}
										}
									}
								}
							}

							this.currentAssoc	=	1;
						}
					}

					const emptyFields = this.allFields.filter((item) => item.required && item.assoc === '');
					if(emptyFields.length == 0) {
						this.saveAssocFields()
					}
				}

			},

			setSavedColumnAssoc() {
				try {
					this.drawFeedMapping()
					this.$store.commit('feeds/SET_STEP_FINISHED', 'two');
				} catch (e) {
					console.log(e);
					this.sbMsg({
						type: 'warn',
						message: 'Asocierea campurilor salvata este invalida. Asociati din nou campurile.'
					});
					return false;
				}
			},
			setCSVProductContainer(val) {
				this.$store.commit('feeds/SET_PRODUCT_CONTAINER', val.trim());
			}
		},
		computed: {
			...mapGetters({
				'assocFields': 'feeds/assocFields',
				'feedDetails': 'feeds/feedDetails',
				'feedId': 'feeds/feedId',
				'feedValidated': 'feeds/feedValidated',
				'hasParsedFields': 'feeds/hasParsedFields',
				'feedStep': 'feeds/feedStep',
				'latestCompletedStep': 'feeds/latestCompletedStep',
				'isLoading': 'feeds/isLoading',
				'isXMLAssoc': 'feeds/isXMLAssoc'
			}),
			...mapState({
				steps: (state) => state.feeds.steps,
				fieldAssocSelected: (state) => state.feeds.fieldAssocSelected,
				assocProductContainer: (state) => state.feeds.assocProductContainer,
				rawFields: (state) => state.feeds.feedRawFields,
				autoMapFields: (state) => state.feeds.feedAutoMapFields,
				parsedFields: (state) => state.feeds.parsedFields,
				loading: (state) => state.feeds.loading,
				allFields: (state) => state.feeds.allFields,
			}),
			currentAssoc: {
				get() {
					return this.$store.state.feeds.currentAssoc;
				},
				set(val) {
					this.$store.commit('feeds/SET_CURRENT_ASSOC', val);
				}
			},
			availableFields() {
				const fields = this.allFields.filter((item) => item.assoc === '' || Array.isArray(item.assoc));

				let sortedFields	=	fields.sort((a, b) => {
					if(a.required && !b.required) {
						return -1;
					}
					if(b.required && !a.required) {
						return 1;
					}
					return a.label.toLowerCase().localeCompare(b.label.toLowerCase());
				});

				return sortedFields;
			},
			hasFields() {

				const hasFields = typeof this.parsedFields !== 'undefined' && Object.keys(this.parsedFields).length > 0;
				console.log("RR2 ");
				if (!hasFields) this.resetAssocFields();
				return hasFields;
			},
			latestStep() {
				return this.latestCompletedStep;
			}
		},
		watch: {
			assocFields(val) {
				this.drawFeedMapping()
			},
			feedValidated(isValidated) {
				if (isValidated) {
					this.$store.commit('feeds/ENABLE_STEP', 'two');
					this.$store.commit('feeds/CLOSE_STEPTS_EXCEPT', 'two');
					this.parseFeedFile();
				}
			},
			rawFields(data) {
				if(data && typeof data[0] == 'object' && this.feedDetails.feed_type != 2 /*xml*/) {
					data 			=	[data[this.currentProduct]];
					this.currentProduct	=	0;
				}

				const parsedFields = this.forgeObject(data, '', '', true);
				this.$store.commit('feeds/SET_PARSED_FIELDS', parsedFields);

				this.drawFeedMapping()
			},
			parsedFields() {
				if (!this.hasParsedFields) this.resetAssocFields();
			},
			latestStep: {
				handler(step) {
					if (
						this.feedStep >= 1 && step === 1
					) {
						this.$store.commit('feeds/ENABLE_STEP', 'two');
						// this.resetAssocFields();
						this.parseFeedFile();
					}
				},
				deep: true,
				immediate: true
			}
		},
		created() {
			EventBus.$on('feedValidated', (data) => {
				this.validationErrors = [];
				console.log("RR5 ");
				this.resetAssocFields();
			});
		},
		beforeDestroy() {
			EventBus.$off();
		}
	};
</script>

<style lang="scss" scoped>
	@import './src/styles/modules/feeds.scss';

	.reset-button {
		float: right;
	}
</style>